import { createContext, useContext, useState, useEffect } from "react";
import { PropTypes } from "prop-types";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const [token, setToken] = useState(localStorage.getItem("token"));

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
    setToken(localStorage.getItem("token"));
  }, []);

  const storeLoginData = (data) => {
    const userType = data.superAdmin ? "superAdmin" : "admin";
    const { id, fullName, email, phoneNumber, image } = data[userType];
    const token = data.token;

    const userData = {
      id,
      fullName,
      email,
      phoneNumber,
      image,
      userType,
    };

    localStorage.setItem("user", JSON.stringify(userData));
    localStorage.setItem("token", token);

    setUser(userData);
    setToken(token);

    setTimeout(() => {
      if (userType === "superAdmin") {
        window.location.replace("/app/dashboard");
      } else {
        window.location.replace("/app/admin/dashboard");
      }
    }, 1000);
  };

  const updateUserData = (updatedData) => {
    const updatedUser = {
      ...user,
      ...updatedData,
    };

    localStorage.setItem("user", JSON.stringify(updatedUser));
    setUser(updatedUser);
  };

  const logout = () => {
    localStorage.clear();
    setUser(null);
    setToken(null);
    window.location.reload();
  };

  return (
    <AuthContext.Provider
      value={{ user, token, storeLoginData, updateUserData, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
